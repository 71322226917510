<template>
  <v-hover #default="{ hover }">
    <v-btn
      v-bind="$attrs"
      v-on="$listeners"
      class="px-11 switch-btn"
      outlined
      :class="[
        value ? 'active switchButtonActive switchButtonColor--text' : 'switchButton--text',
        {'violet switchButtonHoverColor--text': hover}
      ]"
      height="46"
      min-width="50"
      width="50"
      :ripple="false"
      @click="submit"
    >
      <span class="font-normal">
        <slot></slot>
      </span>
    </v-btn>
  </v-hover>
</template>

<script>
export default {
  name: 'SwitchButton',
  inheritAttrs: false,
  props: {
    value: {
      type: Boolean,
      default: false
    },
    isPicker: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    submit() {
      if (this.isPicker) {
        this.$emit('openDialog')
      } else {
        this.$emit('input', !this.value)
      }
    },
  },
}
</script>
<template>
  <div>
    <div class="d-flex">
      <ToggleButton
        v-model="currentBusinessHourly"
        :buttons="optionsBusinessHourly"
        @change="changeBusinessHourlyOption"
      />
      
      <SwitchButton v-model="timeOption" class="ml-8 ml-sm-20 borderColorCustom">24</SwitchButton>
    </div>
  
    <div class="d-flex flex-wrap pt-5" v-if="currentBusinessHourly">
      <HourlyTimePicker
        v-for="item in schedule"
        :key="item.day"
        :day="item.day"
        :simple="false"
        :active.sync="item.active"
        :valid.sync="item.valid"
        :timeOption="timeOption"
        :timeRange.sync="item.range"
        @updateRange="updateRange"
      />
    </div>
    
    <HourlyTimePicker
      v-else
      class="pt-5"
      :active.sync="simpleSchedule.active"
      :valid.sync="simpleSchedule.valid"
      :timeOption="timeOption"
      :timeRange.sync="simpleSchedule.range"
      @updateRange="updateRange"
    />
  </div>
</template>

<script>
import ToggleButton from '@/components/buttons/ToggleButton'
import HourlyTimePicker from '@/components/pickers/HourlyTimePicker'
import SwitchButton from '@/components/controls/SwitchButton'

export default {
  name: 'HoursOfOperationCard',
  inheritAttrs: false,
  props: {
    hoursOfOperation: {
      required: true
    },
    validateHoursOfOperation: {
      type: Boolean,
      required: true
    },
  },
  components: { ToggleButton, HourlyTimePicker, SwitchButton },
  data: () => ({
    timeOption: false,
    openSimpleSchedule: false,
    disableParser: false,
    currentBusinessHourly: 0,
    optionsBusinessHourly: [
      { name: 'Simple', id: 0 },
      { name: 'Advanced', id: 1 }
    ],
    simpleSchedule: { valid: true, active: true, range: { start: [9, 0], end: [20, 0] } },
    schedule: [
      { day: 'Sun', valid: true, active: true, range: { start: [9, 0], end: [20, 0] } },
      { day: 'Mon', valid: true, active: true, range: { start: [9, 0], end: [20, 0] } },
      { day: 'Tue', valid: true, active: true, range: { start: [9, 0], end: [20, 0] } },
      { day: 'Wed', valid: true, active: true, range: { start: [9, 0], end: [20, 0] } },
      { day: 'Thu', valid: true, active: true, range: { start: [9, 0], end: [20, 0] } },
      { day: 'Fri', valid: true, active: true, range: { start: [9, 0], end: [20, 0] } },
      { day: 'Sat', valid: true, active: true, range: { start: [9, 0], end: [20, 0] } }
    ],
  }),
  methods: {
    parseProps(schedule) {
      if (schedule) {
        const hoursOfOperation = JSON.parse(schedule)
        const simpleRange = {
          start: [+hoursOfOperation[0].openTime.hour, +hoursOfOperation[0].openTime.minute],
          end: [+hoursOfOperation[0].closeTime.hour, +hoursOfOperation[0].closeTime.minute]
        }
        const isSimple = hoursOfOperation.every(item =>
          !item.isClosed &&
          simpleRange.start[0] === +item.openTime.hour &&
          simpleRange.start[1] === +item.openTime.minute &&
          simpleRange.end[0] === +item.closeTime.hour &&
          simpleRange.end[1] === +item.closeTime.minute
        )
        this.simpleSchedule = {
          valid: true,
          active: true,
          range: simpleRange
        }
        this.schedule = this.schedule.map((item, i) => {
          item.valid = true
          item.active = !hoursOfOperation[i].isClosed
          item.range = {
            start: [+hoursOfOperation[i].openTime.hour, +hoursOfOperation[i].openTime.minute],
            end: [+hoursOfOperation[i].closeTime.hour, +hoursOfOperation[i].closeTime.minute]
          }
          return item
        })

        if (isSimple) {
          this.currentBusinessHourly = 0
        } else {
          this.currentBusinessHourly = 1
        }
      } else {
        this.updateHoursOfOperation()
      }
    },
    updateRange(update = true) {
      if (this.currentBusinessHourly) {
        const valid = this.schedule.every(item => item.valid)
        this.$emit('update:validateHoursOfOperation', valid)
        !valid || !update || this.updateHoursOfOperation()
      } else {
        this.$emit('update:validateHoursOfOperation', this.simpleSchedule.valid)
        !this.simpleSchedule.valid || !update || this.updateHoursOfOperation()
      }
    },
    updateHoursOfOperation() {
      let hoursOfOperation = []
      if (this.currentBusinessHourly) {
        hoursOfOperation = this.schedule.map(day => ({
          openTime: { hour: day.range.start[0], minute: day.range.start[1] },
          closeTime: { hour: day.range.end[0], minute: day.range.end[1] },
          isClosed: !day.active
        }))
      } else {
        hoursOfOperation = this.schedule.map(() => ({
          openTime: { hour: this.simpleSchedule.range.start[0], minute: this.simpleSchedule.range.start[1] },
          closeTime: { hour: this.simpleSchedule.range.end[0], minute: this.simpleSchedule.range.end[1] },
          isClosed: !this.simpleSchedule.active
        }))
        this.openSimpleSchedule = true
      }
      this.disableParser = true
      this.$emit('update:hoursOfOperation', JSON.stringify(hoursOfOperation))
    },
    changeBusinessHourlyOption(value) {
      if (value) {
        this.schedule = this.schedule.map(item => ({
          ...this.simpleSchedule,
          day: item.day
        }))
        this.updateRange()
      }
    },
  },
  watch: {
    hoursOfOperation: {
      immediate: true,
      handler(schedule) {
        this.disableParser || this.parseProps(schedule)
        this.disableParser = false
      },
    },
    currentBusinessHourly: {
      handler(value) {
        this.disableParser = !value
        this.updateRange(true)
      },
    },
  },
}
</script>

<template>
  <v-card v-bind="$attrs" flat outlined>
    <v-row class="destination-content d-block d-md-flex align-center" no-gutters>
      <v-col cols="12" md="3" xl="2">
        <TextDescription :description="description"/>
      </v-col>
      
      <v-col cols="12" md="9" xl="10">
        <v-form ref="form" v-model="validateForm">
          <v-row no-gutters>
            <v-col
              v-if="onPageCreateCampaign"
              class="d-flex flex-column justify-center pr-sm-11 pb-4 pb-sm-10"
              cols="12" sm="4" md="3"
            >
              <span class="font-normal nameField--text text-sm-right">Choose an Option</span>
            </v-col>
            <v-col class="pb-10" cols="12" sm="8" md="9" v-if="onPageCreateCampaign">
              <ToggleButton
                v-model="currentDestinationOptions"
                :buttons="destinationOptions"
                @input="openCreateCallRoute"
              />
            </v-col>
            
            <v-col
              class="d-flex flex-column justify-center pr-sm-11 pb-4 pb-sm-10"
              cols="12" sm="4" md="3"
            >
              <span class="font-normal nameField--text text-sm-right">Destination name</span>
              <portal-target
                class="field-message font-normal error--text text-sm-right"
                name="destinationName"
              ></portal-target>
            </v-col>
  
            <v-col class="pb-10" cols="12" sm="8" md="9">
              <FormField
                v-model="formDataDestination.name"
                class="field-container"
                :loading="loadDetail"
                :rules="rules"
              >
                <template #message="{ key, message }">
                  <portal to="destinationName" v-if="!!message">
                    {{ message }}
                  </portal>
                </template>
              </FormField>
            </v-col>
  
            <v-col
              class="d-flex flex-column justify-center pr-sm-11 pb-4 pb-sm-10"
              cols="12" sm="4" md="3"
            >
              <span class="font-normal nameField--text text-sm-right">Buyer</span>
              <portal-target
                class="field-message font-normal error--text text-sm-right"
                name="changedBuyer"
              ></portal-target>
            </v-col>
            
            <v-col class="d-flex flex-wrap" cols="12" sm="8" md="9">
              <AutocompleteField
                class="field-container mr-sp-11 pb-5"
                v-model="formDataDestination['buyer_id']"
                :loading="loadingAllBuyers"
                :items="buyersAll"
                item-text="name"
                item-value="id"
                :rules="rules"
                checkBg
              >
                <template #message="{ key, message }">
                  <portal to="changedBuyer" v-if="!!message">
                    {{ message }}
                  </portal>
                </template>
              </AutocompleteField>
  
              <div class="pb-10">
                <ActionOutlinedButton
                  class="borderColorCustom"
                  color="outlinedPrimaryButtonColor"
                  @click="openDialogBuyer"
                  height="46"
                >
                  New Buyer
                </ActionOutlinedButton>
              </div>
            </v-col>
  
            <v-col
              class="d-flex flex-column justify-center pr-sm-11 pb-4 pb-sm-10"
              cols="12" sm="4" md="3"
            >
              <span class="font-normal nameField--text text-sm-right">Choose an Option</span>
            </v-col>
            <v-col class="pb-10" cols="12" sm="8" md="9">
              <ToggleButton v-model="currentType" :buttons="numberType"/>
            </v-col>
            
            <v-scroll-y-transition hide-on-leave>
              <v-col cols="12" v-if="currentType === 1">
                <v-row no-gutters>
                  <v-col
                    class="d-flex flex-column justify-center pr-sm-11 pb-4 pb-sm-10"
                    cols="12" sm="4" md="3"
                  >
                    <span class="font-normal nameField--text text-sm-right">Destination</span>
                    <portal-target
                      class="field-message font-normal error--text text-sm-right"
                      name="destinationNumber"
                    ></portal-target>
                  </v-col>
  
                  <v-col class="pb-10" cols="12" sm="8" md="9">
                    <FormField
                      v-model="formDataDestination.destination"
                      class="field-container"
                      :loading="loadDetail"
                      :rules="rules"
                    >
                      <template #message="{ key, message }">
                        <portal to="destinationNumber" v-if="!!message">
                          {{ message }}
                        </portal>
                      </template>
                    </FormField>
                  </v-col>
                </v-row>
              </v-col>
            </v-scroll-y-transition>
            
            <v-scroll-y-transition hide-on-leave>
              <v-col cols="12" v-if="currentType === 2">
                <v-row no-gutters>
                  <v-col
                    class="d-flex flex-column justify-center pr-sm-11 pb-4 pb-sm-10"
                    cols="12" sm="4" md="3"
                  >
                    <span class="font-normal nameField--text text-sm-right">SIP Endpoint</span>
                    <portal-target
                      class="field-message font-normal error--text text-right text-sm-right"
                      name="sipPoint"
                    ></portal-target>
                  </v-col>
  
                  <v-col class="pb-10" cols="12" sm="8" md="9">
                    <FormField
                      v-model="formDataDestination['sip_point']"
                      class="field-container"
                      :loading="loadDetail"
                      :rules="rules"
                    >
                      <template #message="{ key, message }">
                        <portal to="sipPoint" v-if="!!message">
                          {{ message }}
                        </portal>
                      </template>
                    </FormField>
                  </v-col>
  
                  <v-col
                    class="d-flex flex-column justify-center pr-sm-11 pb-4 pb-sm-10"
                    cols="12" sm="4" md="3"
                  >
                    <span class="font-normal nameField--text text-sm-right">SIP User Name</span>
                  </v-col>
  
                  <v-col class="pb-10" cols="12" sm="8" md="9">
                    <FormField
                      v-model="formDataDestination['sip_login']"
                      class="field-container"
                      :loading="loadDetail"
                    />
                  </v-col>
  
                  <v-col
                    class="d-flex flex-column justify-center pr-sm-11 pb-4 pb-sm-10"
                    cols="12" sm="4" md="3"
                  >
                    <span class="font-normal nameField--text text-sm-right">SIP Password</span>
                  </v-col>
  
                  <v-col class="pb-10" cols="12" sm="8" md="9">
                    <FormField
                      v-model="formDataDestination['sip_password']"
                      class="field-container"
                      type="password"
                      :loading="loadDetail"
                    />
                  </v-col>
                </v-row>
              </v-col>
            </v-scroll-y-transition>
  
            <v-col
              class="d-flex flex-column justify-center pr-sm-11 pb-4 pb-sm-10"
              cols="12" sm="4" md="3"
            >
              <span class="font-normal nameField--text text-sm-right">Timezone</span>
              <portal-target
                class="field-message font-normal error--text text-sm-right"
                name="destinationTimezone"
              ></portal-target>
            </v-col>
  
            <v-col class="pb-10" cols="12" sm="8" md="9">
              <AutocompleteField
                class="field-container"
                v-model="formDataDestination['timezone_id']"
                :loading="loadDetail"
                :items="timezones"
                item-text="display"
                item-value="id"
                :rules="rules"
                checkBg
              >
                <template #message="{ key, message }">
                  <portal to="destinationTimezone" v-if="!!message">
                    {{ message }}
                  </portal>
                </template>
              </AutocompleteField>
            </v-col>

            <v-col
              class="d-flex flex-column justify-center pr-sm-11 pb-4 pb-sm-10"
              cols="12" sm="4" md="3"
            >
              <span class="font-normal nameField--text text-sm-right">Ringing Timeout</span>
              <portal-target
                class="field-message font-normal error--text text-sm-right"
                name="destinationConnectionTimeout"
              ></portal-target>
            </v-col>

            <v-col class="pb-10" cols="12" sm="8" md="9">
              <FormField
                v-model="formDataDestination['connection_timeout']"
                class="field-container"
                type="number"
                :loading="loadDetail"
                :rules="rulesConnectionTimeout"
              >
                <template #message="{ key, message }">
                  <portal to="destinationConnectionTimeout" v-if="!!message">
                    {{ message }}
                  </portal>
                </template>
              </FormField>
            </v-col>
  
            <v-col
              class="d-flex flex-column justify-center pr-sm-11 pb-4 pb-sm-10"
              cols="12" sm="4" md="3"
            >
              <span class="font-normal nameField--text text-sm-right">Business Hours</span>
            </v-col>
  
            <v-col class="pb-10" cols="12" sm="8" md="9">
              <SwitchLg v-model="hoursOfOperation" bgColor="switcherViolet"/>
            </v-col>
            
            <v-expand-transition>
              <v-col
                v-if="hoursOfOperation"
                class="pb-10 d-flex align-start flex-column"
                cols="12" sm="8" md="9" offset="0" offset-sm="4" offset-md="3"
              >
                <HoursOfOperationCard
                  :hoursOfOperation.sync="formDataDestination['hours_of_operation']"
                  :validateHoursOfOperation.sync="validateHoursOfOperation"
                />
              </v-col>
            </v-expand-transition>
  
            <v-col
              class="text-sm-end font-normal nameField--text pr-sm-11 pb-4 pb-sm-0"
              cols="12" sm="4" md="3"
            >
              Area code
            </v-col>

            <v-col class="pb-10" cols="12" sm="8" md="9">
              <SwitchLg
                v-model="areaCode"
                bgColor="switcherViolet"
              />
            </v-col>
  
            <v-expand-transition>
              <v-col cols="12" v-if="areaCode">
                <v-row no-gutters>
                  <v-col
                    class="d-flex flex-column justify-center pr-sm-11 pb-4 pb-sm-10"
                    cols="12" sm="4" md="3"
                  >
                    <span class="font-normal nameField--text text-sm-right">Country</span>
                    <portal-target
                      class="field-message font-normal error--text text-sm-right"
                      name="areaCodeCountries"
                    ></portal-target>
                  </v-col>
        
                  <v-col class="pb-10" cols="12" sm="8" md="9">
                    <AutocompleteField
                      class="field-container"
                      v-model="countryId"
                      :items="countries"
                      item-text="name"
                      item-value="id"
                      :rules="rules"
                      checkBg
                    >
                      <template #message="{ key, message }">
                        <portal to="areaCodeCountries" v-if="!!message">
                          {{ message }}
                        </portal>
                      </template>
                    </AutocompleteField>
                  </v-col>
        
                  <v-col
                    class="d-flex flex-column justify-center pr-sm-11 pb-4 pb-sm-10"
                    cols="12" sm="4" md="3"
                  >
                    <span class="font-normal nameField--text text-sm-right">Codes</span>
                    <portal-target
                      class="field-message font-normal error--text text-sm-right"
                      name="areaCodeArea"
                    ></portal-target>
                  </v-col>
        
                  <v-col class="pb-10" cols="12" sm="8" md="9">
                    <AutocompleteField
                      class="expandable-field-container create-container"
                      v-model="areas"
                      :items="codes"
                      item-text="name"
                      item-value="id"
                      :menuProps="{ maxHeight: 255 }"
                      :rules="rulesArray"
                      :loading="loadingAreas"
                      :no-data-text="countryId ? 'Area not found' : 'Please select a country'"
                      height="100%"
                      chips
                      small-chips
                      deletable-chips
                      multiple
                      checkBg
                    >
                      <template #message="{ key, message }">
                        <portal to="areaCodeArea" v-if="!!message">
                          {{ message }}
                        </portal>
                      </template>
                    </AutocompleteField>
                  </v-col>
                </v-row>
              </v-col>
            </v-expand-transition>
          </v-row>
        </v-form>
      </v-col>
    </v-row>
    
    <v-row
      class="setting-content d-block d-md-flex align-center"
      no-gutters
    >
      <v-col cols="12" md="9" xl="10" offset="0" offset-md="3" offset-xl="2">
        <v-row no-gutters>
          <v-col
            class="d-flex align-center justify-sm-end pr-sm-11 pt-6 pb-4 pb-sm-8"
            cols="12" sm="4" md="3"
          >
            <span class="font-large sectionTitle--text text-sm-right">CAP SETTINGS</span>
          </v-col>
          <v-col class="pb-10" cols="12" sm="8" md="9"></v-col>
  
          <v-col
            class="d-flex flex-column justify-center pr-sm-11 pb-4 pb-sm-10"
            cols="12" sm="4" md="3"
          >
            <span class="font-normal nameField--text text-sm-right">Cap on</span>
          </v-col>
  
          <v-col class="d-flex flex-wrap pb-5" cols="12" sm="8" md="9">
            <div class="pb-5">
              <ToggleButton
                class="mr-sp-11"
                v-model="formDataDestination['cap_on_id']"
                :buttons="capOnOptions"
                :class="{ 'flex-column': isMobile }"
              />
            </div>

            <div class="pb-5">
              <ActionOutlinedButton
                class="borderColorCustom"
                color="outlinedPrimaryButtonColor"
                @click="openDialogResetCap"
                height="46"
                v-if="onShowResetCaps"
              >
                Reset Caps
              </ActionOutlinedButton>
            </div>
          </v-col>
          
          <v-col cols="12">
            <RevenueCapSettings
              v-if="formDataDestination['cap_on_id'] === 3"
              :revenueCapSettings.sync="revenueCapacity"
            />
          
            <CapSettings v-else :capSettings.sync="capacity" />
          </v-col>
          
          <v-col
            class="d-flex align-center justify-start justify-sm-end pr-sm-11 pt-6 pb-4 pb-sm-8"
            cols="12" sm="4" md="3"
          >
            <span class="font-large sectionTitle--text text-sm-right">CONCURRENCY SETTINGS</span>
          </v-col>
          <v-col class="pb-10" cols="12" sm="8" md="9"></v-col>
          
          <v-col cols="12">
            <ConcurrencySettings :concurrencySettings.sync="concurrency" />
          </v-col>
  
          <v-col cols="12" sm="8" md="9" offset="0" offset-sm="4" offset-md="3" class="mt-2 mt-sm-8">
            <CancelButton
              v-if="isExpandPanel"
              class="mr-8 mr-sm-10"
              @click="closePanel"
            >
              Cancel
            </CancelButton>
            
            <ActionButton
              @click="submitForm"
              :loading="loadingAction"
            >
              {{ destinationSlug ? 'Update' : 'Create' }}
            </ActionButton>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    
    <DialogBuyer
      v-model="dialogCreateBuyer"
      @createSuccess="createBuyerSuccess"
    />
  
    <DialogResetCaps
      v-model="dialogResetCaps"
      :itemId="destinationSlug"
      forWhomAction="Destination"
    />
  </v-card>
</template>

<script>
import TextDescription from '@/components/description/TextDescription'
import ToggleButton from '@/components/buttons/ToggleButton'
import ActionOutlinedButton from '@/components/buttons/ActionOutlinedButton'
import CancelButton from '@/components/buttons/CancelButton'
import ActionButton from '@/components/buttons/ActionButton'
import FormField from '@/components/inputs/FormField'
import AutocompleteField from '@/components/select/AutocompleteField'
import SwitchLg from '@/components/controls/SwitchLg'
import CapSettings from '@/components/destination/CapSettings'
import RevenueCapSettings from '@/components/destination/RevenueCapSettings'
import ConcurrencySettings from '@/components/destination/ConcurrencySettings'
import HoursOfOperationCard from '@/components/pickers/HoursOfOperationCard'
import DialogBuyer from '@/components/dialog/DialogBuyer'
import DialogResetCaps from '@/components/dialog/DialogResetCaps'

import { mapState, mapGetters } from 'vuex'
import { Types as indexTypes } from '@/store'
import { getAllBuyers } from '@/api/buyer-routes'
import { getCapOnOptions, createDestination, editDestinationForManager } from '@/api/destination-routes'
import { getAreas } from '@/api/app-routes'

export default {
  name: 'CardDestination',
  inheritAttrs: false,
  components: { TextDescription, ToggleButton, ActionOutlinedButton, CancelButton, ActionButton, FormField, AutocompleteField, SwitchLg, CapSettings, RevenueCapSettings, ConcurrencySettings, HoursOfOperationCard, DialogBuyer, DialogResetCaps },
  props: {
    description: {
      type: Object,
      required: true
    },
    destinationSlug: {
      type: String | Number,
      default: null
    },
    destination: {
      type: Object,
      default: () => ({})
    },
    loadDetail: {
      type: Boolean,
      default: false
    },
    isExpandPanel: {
      type: Boolean,
      default: false
    },
    onPageCreateCampaign: {
      type: Boolean,
      default: false
    },
    onShowResetCaps: {
      type: Boolean,
      default: false
    },
    loadDestinationCaps: {
      type: Boolean,
      default: false
    },
    capOnOptionsFromRelative: {
      type: Array,
      default: () => []
    },
  },
  data: () => ({
    loadingAllBuyers: false,
    validateForm: true,
    dialogCreateBuyer: false,
    dialogResetCaps: false,
    changedBuyerId: null,
    validateHoursOfOperation: true,
    loadingAction: false,
    currentDestinationOptions: 2,
    destinationOptions: [
      { name: 'Add Existing', id: 1 },
      { name: 'Create new', id: 2 }
    ],
    currentType: 1,
    numberType: [
      { name: 'Number', id: 1 },
      { name: 'SIP', id: 2 }
    ],
    capOnOptions: [],
    formDataDestination: {
      name: null,
      destination: null,
      'buyer_id': null,
      'status_id': 1,
      'timezone_id': null,
      'connection_timeout': null,
      'sip_point': null,
      'sip_login': null,
      'sip_password': null,
      'hours_of_operation': null,
      'cap_on_id': 1,
    },
    capacity: {
      'global_cap': null,
      'monthly_cap': null,
      'daily_cap': null,
      'hourly_cap': null,
    },
    capacityDefault: {
      'global_cap': null,
      'monthly_cap': null,
      'daily_cap': null,
      'hourly_cap': null,
    },
    revenueCapacity: {
      'global_revenue_cap': null,
      'monthly_revenue_cap': null,
      'daily_revenue_cap': null,
      'hourly_revenue_cap': null,
    },
    revenueCapacityDefault: {
      'global_revenue_cap': null,
      'monthly_revenue_cap': null,
      'daily_revenue_cap': null,
      'hourly_revenue_cap': null,
    },
    concurrency: {
      'max_concurrency': null,
      'hourly_concurrency': null
    },
    phoneRate: null,
    hoursOfOperation: false,
    rules: [],
    rulesArray: [],
    rulesConnectionTimeout: [],
    buyersAll: [],
    areaCode: false,
    loadingAreas: false,
    countryId: null,
    codes: [],
    areas: [],
  }),
  computed: {
    ...mapState({
      countries: state => state.countries,
      timezones: state => state.timezones,
    }),
    ...mapGetters({
      countries: indexTypes.getters.GET_COUNTRY_WITH_CODE,
    }),
    isMobile() {
      return this.$vuetify.breakpoint.width < 420
    },
  },
  mounted() {
    this.loadBuyersAll()
  },
  methods: {
    async loadAreaCodes() {
      this.loadingAreas = true

      const { success, payload, message } = await getAreas({
        'country_id': this.countryId
      })

      if (success) {
        this.codes = payload.map(area => ({
          name: `${area.code} - ${area.name}`,
          id: area.id,
        }))
      } else {
        !!message && this.$notify({ type: 'error-bg', duration: 15000, text: message })
      }

      this.loadingAreas = false
    },
    async loadBuyersAll() {
      this.loadingAllBuyers = true
      
      const { success, payload, message } = await getAllBuyers()
      
      if (success) {
        this.buyersAll = payload
      } else {
        !!message && this.$notify({ type: 'error-bg', duration: 15000, text: message })
      }
      
      this.loadingAllBuyers = false
    },
    parseData(destination) {
      Object.keys(this.formDataDestination).forEach(name => {
        this.formDataDestination[name] = destination[name]
      })
      Object.keys(this.capacity).forEach(name => {
        this.capacity[name] = destination[name]
      })
      Object.keys(this.revenueCapacity).forEach(name => {
        this.revenueCapacity[name] = destination[name]
      })
      Object.keys(this.concurrency).forEach(name => {
        this.concurrency[name] = destination[name]
      })

      this.areaCode = !!destination.areas?.length
      if (this.areaCode) {
        this.countryId = destination.areas[0]['country_id']
        this.areas = destination.areas.map(area => area.id)
      }
      
      this.hoursOfOperation = !!this.formDataDestination['hours_of_operation']
      this.currentType = !!this.formDataDestination.destination ? 1 : 2
    },
    async loadCapOnOptions() {
      const { success, payload, message } = await getCapOnOptions()
      
      if (success) {
        this.capOnOptions = payload.map(item => ({
          name: item.name,
          id: item.id
        }))
      } else {
        !!message && this.$notify({ type: 'error-bg', duration: 15000, text: message })
      }
    },
    async submitForm() {
      this.hoursOfOperation || (this.validateHoursOfOperation = true)
      await this.validationForm()
      if (this.$refs.form.validate() && this.validateHoursOfOperation) {
        if (this.currentType === 1) {
          this.formDataDestination['sip_point'] = null
          this.formDataDestination['sip_login'] = null
          this.formDataDestination['sip_password'] = null
        } else if (this.currentType === 2) {
          this.formDataDestination.destination = null
          this.phoneRate = null
        }

        this.loadingAction = true
        const capacity = this.formDataDestination['cap_on_id'] === 3
          ? Object.assign({}, this.onlyNumber(this.revenueCapacity), this.capacityDefault)
          : Object.assign({}, this.onlyNumber(this.capacity), this.revenueCapacityDefault)
        
        let formData = Object.assign({},
          this.formDataDestination,
          this.onlyNumber(capacity),
          this.onlyNumber(this.concurrency)
        )

        if (this.areaCode) {
          formData = Object.assign(formData, {
            'country_id': this.countryId,
            areas: this.areas
          })
        }
        
        const { success, payload, message } = this.destinationSlug
          ? await editDestinationForManager({ destinationId: this.destinationSlug, formData })
          : await createDestination(formData)

        if (success) {
          this.$notify({ type: 'success-bg', text: message })
          if (this.isExpandPanel) {
            this.$emit('createSuccess', payload)
          } else {
            this.$router.push({ name: 'ManageDestinations' })
          }
        } else {
          !!message && this.$notify({ type: 'error-bg', duration: 15000, text: message })
        }

        this.loadingAction = false
      }
      
    },
    validationForm() {
      this.rules = [
        v => !!v ||  'This field is required'
      ]
      this.rulesArray = [
        v => !!v && !!v.length ||  'This field is required'
      ]
      this.rulesConnectionTimeout = [
        v => !(!!v && v <= 4) ||  'The connection timeout must be at least 4'
      ]
    },
    onlyNumber(obj) {
      Object.keys(obj).forEach(name => {
        obj[name] = obj[name] ? +obj[name] : null
      })
      return obj
    },
    closePanel() {
      this.$emit('closePanel')
    },
    openCreateCallRoute() {
      this.$emit('openCreateCallRoute')
    },
    openDialogBuyer() {
      this.dialogCreateBuyer = true
    },
    openDialogResetCap() {
      this.dialogResetCaps = true
      this.loadDestinationCaps = true
    },
    createBuyerSuccess(newBuyer) {
      this.buyersAll.push(newBuyer)
      this.changedBuyerId = newBuyer.id
    }
  },
  watch: {
    destination: {
      immediate: true,
      handler(destination) {
        if (destination && Object.keys(destination).length) {
          this.parseData(destination)
        }
      }
    },
    hoursOfOperation: {
      handler(value) {
        if (!value) {
          this.formDataDestination['hours_of_operation'] = null
        }
      }
    },
    buyersAll: {
      handler() {
        if (this.changedBuyerId) {
          this.formDataDestination['buyer_id'] = this.changedBuyerId
          this.changedBuyerId = null
        }
      }
    },
    capOnOptionsFromRelative: {
      immediate: true,
      handler(options) {
        if (options.length) {
          this.capOnOptions = options
        } else {
          this.loadCapOnOptions()
        }
      }
    },
    areaCode: {
      handler(value) {
        if (!value) {
          this.countryId = null
          this.codes = []
          this.areas = []
        }
      }
    },
    countryId: {
      handler(id, oldId) {
        if (!!id) {
          if (!!oldId) {
            this.areas = []
          }
          this.loadAreaCodes()
        }
      }
    },
  },
}
</script>

<style lang="scss">
.create-destination-card {
  &.expandPanelBg {
    padding: 40px 0!important;
    border: none!important;
  }
  
  &:not(.expandPanelBg):not(.no-padding) {
    border: 1px solid var(--v-cardBorder-base)!important;
  }
}
</style>

<template>
  <v-row no-gutters>
    <v-col
      class="text-sm-end font-normal nameField--text pr-sm-11 pb-4 pb-sm-0"
      cols="12" sm="4" md="3"
    >Max concurrency</v-col>
  
    <v-col class="pb-10 d-flex" cols="12" sm="8" md="9">
      <SwitchAndNumberInput v-model="concurrency['max_concurrency']" />
    </v-col>
    
<!--    <v-col-->
<!--      class="text-sm-end font-normal nameField&#45;&#45;text pr-sm-11 pb-4 pb-sm-0"-->
<!--      cols="12" sm="4" md="3"-->
<!--    >Hourly concurrency</v-col>-->
<!--  -->
<!--    <v-col class="pb-10 d-flex" cols="12" sm="8" md="9">-->
<!--      <SwitchAndNumberInput v-model="concurrency['hourly_concurrency']" />-->
<!--    </v-col>-->
  </v-row>
</template>

<script>
import SwitchAndNumberInput from '@/components/controls/SwitchAndNumberInput'

export default {
  name: 'ConcurrencySettings',
  inheritAttrs: false,
  components: { SwitchAndNumberInput },
  props: {
    concurrencySettings: {
      type: Object,
      required: true
    }
  },
  computed: {
    concurrency: {
      get() {
        return this.concurrencySettings
      },
      set(value) {
        this.$emit('concurrencySettings:update', value)
      }
    }
  }
}
</script>

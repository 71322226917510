<template>
  <v-row no-gutters>
    <v-col
      class="text-sm-end font-normal nameField--text pr-sm-11 pb-4 pb-sm-0"
      cols="12" sm="4" md="3"
    >Global Revenue Cap, $</v-col>
  
    <v-col class="pb-10" cols="12" sm="8" md="9">
      <SwitchAndNumberInput
        v-model="revenueCapacity['global_revenue_cap']"
        :min="0"
        :step="0.01"
      />
    </v-col>
  
    <v-col
      class="text-sm-end font-normal nameField--text pr-sm-11 pb-4 pb-sm-0"
      cols="12" sm="4" md="3"
    >Monthly Revenue Cap, $</v-col>
    
    <v-col class="pb-10 d-flex" cols="12" sm="8" md="9">
      <SwitchAndNumberInput
        v-model="revenueCapacity['monthly_revenue_cap']"
        :min="0"
        :step="0.01"
      />
    </v-col>
  
    <v-col
      class="text-sm-end font-normal nameField--text pr-sm-11 pb-4 pb-sm-0"
      cols="12" sm="4" md="3"
    >Daily Revenue Cap, $</v-col>
    
    <v-col class="pb-10 d-flex" cols="12" sm="8" md="9">
      <SwitchAndNumberInput
        v-model="revenueCapacity['daily_revenue_cap']"
        :min="0"
        :step="0.01"
      />
    </v-col>
  
    <v-col
      class="text-sm-end font-normal nameField--text pr-sm-11 pb-4 pb-sm-0"
      cols="12" sm="4" md="3"
    >Hourly Revenue Cap, $</v-col>
    
    <v-col class="pb-10 d-flex" cols="12" sm="8" md="9">
      <SwitchAndNumberInput
        v-model="revenueCapacity['hourly_revenue_cap']"
        :min="0"
        :step="0.01"
      />
    </v-col>
  </v-row>
</template>

<script>
import SwitchAndNumberInput from '@/components/controls/SwitchAndNumberInput'

export default {
  name: 'RevenueCapSettings',
  inheritAttrs: false,
  components: { SwitchAndNumberInput },
  props: {
    revenueCapSettings: {
      type: Object,
      required: true
    }
  },
  computed: {
    revenueCapacity: {
      get() {
        return this.revenueCapSettings
      },
      set(value) {
        this.$emit('revenueCapSettings:update', value)
      }
    }
  }
}
</script>

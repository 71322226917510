<template>
  <v-dialog
    v-model="show"
    v-bind="$attrs"
    content-class="dialogTimePicker timePickerBg"
    width="272px"
    overlay-color="overlayColor"
    @keydown.enter.stop="saveTime"
  >
    <v-row no-gutters v-if="show">
      <v-col cols="8">
        <div
          class="font-large-title text-uppercase timePickerTitle--text text-right"
        >select time</div>
      </v-col>
  
      <v-col class="d-flex align-center justify-end mt-8 timeBody" cols="8">
        <div
          id="time-block-hours"
          class="d-flex flex-column align-center font-normal timeBlock"
        >
          <span class="icon-block">
            <v-hover #default="{ hover }">
              <v-icon
                size="15"
                :color="hover ? 'timePickerIconHover' : 'timePickerIcon'"
                @click="hour -= 1"
              >$arrowUp</v-icon>
            </v-hover>
          </span>
          <span
            class="py-2 number-block text-center range"
            @click="changeHour(getTimeHourPrev(getTimeHourPrev(hour)))"
          >
            {{ getTimeHourPrev(getTimeHourPrev(hour)) }}
          </span>
          
          <span
            class="py-2 number-block text-center range"
            @click="changeHour(getTimeHourPrev(hour))"
          >
            {{ getTimeHourPrev(hour) }}
          </span>
          
          <InputNumberTimePicker
            v-model="hour"
            :min="hourMin"
            :max="hourMax"
          ></InputNumberTimePicker>
          
          <span
            class="py-2 number-block text-center range"
            @click="changeHour(getTimeHourNext(hour))"
          >
            {{ getTimeHourNext(hour) }}
          </span>
          
          <span
            class="py-2 number-block text-center range"
            @click="changeHour(getTimeHourNext(getTimeHourNext(hour)))"
          >
            {{ getTimeHourNext(getTimeHourNext(hour)) }}
          </span>
          <span class="icon-block text-center">
            <v-hover #default="{ hover }">
              <v-icon
                size="15"
                :color="hover ? 'timePickerIconHover' : 'timePickerIcon'"
                @click="hour += 1"
              >$arrowDown</v-icon>
            </v-hover>
          </span>
        </div>
        
        <div
          id="time-block-minutes"
          class="d-flex flex-column align-center font-normal timeBlock"
        >
          <span class="icon-block">
            <v-hover #default="{ hover }">
              <v-icon
                size="15"
                :color="hover ? 'timePickerIconHover' : 'timePickerIcon'"
                @click="minute -= 1"
              >$arrowUp</v-icon>
            </v-hover>
          </span>
          <span
            class="py-2 number-block text-center range"
            @click="changeMinute(getTimeMinutePrev(getTimeMinutePrev(minute)))"
          >
            {{ getTimeMinutePrev(getTimeMinutePrev(minute)) }}
          </span>
  
          <span
            class="py-2 number-block text-center range"
            @click="changeMinute(getTimeMinutePrev(minute))"
          >
            {{ getTimeMinutePrev(minute) }}
          </span>
  
          <InputNumberTimePicker
            v-model="minute"
            :min="0"
            :max="minuteMax"
          ></InputNumberTimePicker>
  
          <span
            class="py-2 number-block text-center range"
            @click="changeMinute(getTimeMinuteNext(minute))"
          >
            {{ getTimeMinuteNext(minute) }}
          </span>
  
          <span
            class="py-2 number-block text-center range"
            @click="changeMinute(getTimeMinuteNext(getTimeMinuteNext(minute)))"
          >
            {{ getTimeMinuteNext(getTimeMinuteNext(minute)) }}
          </span>
          <span class="icon-block text-center">
            <v-hover #default="{ hover }">
              <v-icon
                size="15"
                :color="hover ? 'timePickerIconHover' : 'timePickerIcon'"
                @click="minute += 1"
              >$arrowDown</v-icon>
            </v-hover>
          </span>
        </div>
      </v-col>
      
      <v-col class="d-flex align-center justify-end mt-8" cols="4">
        <span v-if="timeOption" class="violet--text">24 hr</span>
        
        <ToggleButton
          v-else
          class="d-flex flex-column"
          v-model="currentTimeOption"
          :buttons="timeOptions"
          height="34"
          dense
        />
      </v-col>
      <v-col class="mt-8" cols="12">
        <div class="d-flex flex-grow-1 justify-end">
          <CancelButton class="mr-6 px-6" height="38" @click="closePicker">Cancel</CancelButton>
          
          <ActionButton class="px-6" height="38" @click="saveTime">Save</ActionButton>
        </div>
      </v-col>
    </v-row>
  </v-dialog>
</template>

<script>
import InputNumberTimePicker from '@/components/inputs/InputNumberTimePicker'
import ToggleButton from '@/components/buttons/ToggleButton'
import CancelButton from '@/components/buttons/CancelButton'
import ActionButton from '@/components/buttons/ActionButton'

export default {
  name: 'CustomTimePicker',
  inheritAttrs: false,
  components: { InputNumberTimePicker, ToggleButton, CancelButton, ActionButton },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    timeOption: {
      type: Boolean,
      default: false
    },
    pickerHour: {
      type: Number,
      required: true
    },
    pickerMinute: {
      type: Number,
      required: true
    },
  },
  data: () => ({
    currentTimeOption: 0,
    timeOptions: [
      { id: 0, name: 'am' },
      { id: 1, name: 'pm' },
    ],
    hour: 9,
    hourMin: 0,
    hourMax: 23,
    minute: 0,
    minuteMax: 59,
  }),
  computed: {
    show: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      },
    },
  },
  methods: {
    parseProps() {
      if (this.timeOption) {
        this.hourMin = 0
        this.hourMax = 23
        this.hour = this.pickerHour
      } else {
        this.hourMin = 1
        this.hourMax = 12
        if (this.pickerHour > 12) {
          this.hour = this.pickerHour - 12
          this.currentTimeOption = 1
        } else {
          if (this.pickerHour === 0) {
            this.hour = 12
          } else {
            this.hour = this.pickerHour
          }
          if (this.pickerHour === 12) {
            this.currentTimeOption = 1
          } else {
            this.currentTimeOption = 0
          }
        }
      }
      this.minute = this.pickerMinute
    },
    saveTime() {
      let timeRange = []
      if (this.timeOption) {
        timeRange = [+this.hour, +this.minute]
      } else {
        if (this.currentTimeOption) {
          if (+this.hour === 12) {
            timeRange = [+this.hour, +this.minute]
          } else {
            if (this.hour + 12 === 24) {
              timeRange = [0, +this.minute]
            } else {
              timeRange = [+this.hour + 12, +this.minute]
            }
          }
        } else {
          timeRange = [+this.hour , +this.minute]
        }
      }
      this.$emit('updateTime', timeRange)
      this.closePicker()
    },
    closePicker() {
      this.show = false
    },
    changeHour(hour) {
      this.hour = +hour
    },
    changeMinute(minute) {
      this.minute = +minute
    },
    addScrollListeners() {
      setTimeout(() => {
        document.getElementById('time-block-hours').addEventListener('wheel', e => {
          const delta = Math.sign(-e.deltaY || 1)
          const hour = this.hour - delta
          if (hour > this.hourMax) {
            this.hour = this.hourMin
          } else if (hour < 0) {
            this.hour = this.hourMax
          } else if (hour === 0 && !this.timeOption) {
            this.hour = delta === 1 ? 12 : 1
          } else {
            this.hour = hour
          }
        }, false)
        document.getElementById('time-block-minutes').addEventListener('wheel', e => {
          const delta = Math.sign(-e.deltaY || 1)
          const minute = this.minute - delta
          if (minute > this.minuteMax) {
            this.minute = 0
          } else if (minute < 0) {
            this.minute = this.minuteMax
          } else {
            this.minute = minute
          }
        }, false)
      })
    },
    getTime(time) {
      return `${time}`.length === 1 ? `0${time}` : time
    },
    getTimeHourPrev(time) {
      const hour = (+time - 1) < this.hourMin ? this.hourMax : +time - 1
      return `${hour}`.length === 1 ? `0${hour}` : hour
    },
    getTimeHourNext(time) {
      const hour = (+time + 1) > this.hourMax ? this.hourMin : +time + 1
      return `${hour}`.length === 1 ? `0${hour}` : hour
    },
    getTimeMinutePrev(time) {
      const minute = (+time - 1) < 0 ? this.minuteMax : +time - 1
      return `${minute}`.length === 1 ? `0${minute}` : minute
    },
    getTimeMinuteNext(time) {
      const minute = (+time + 1) > this.minuteMax ? 0 : +time + 1
      return `${minute}`.length === 1 ? `0${minute}` : minute
    },
  },
  watch: {
    value: {
      handler(value) {
        if (value) {
          this.addScrollListeners()
          this.parseProps()
        } else {
          this.rules = []
          document.getElementById('time-block-hours').removeEventListener('wheel', () => {}, false)
          document.getElementById('time-block-minutes').removeEventListener('wheel', () => {}, false)
        }
      },
    },
    hour: {
      handler(value) {
        if (+value > this.hourMax) {
          this.$nextTick(() => {
            this.hour = this.hourMin
          })
        } else if (+value < this.hourMin) {
          this.hour = this.getTime(this.hourMax)
        }
      },
    },
    minute: {
      immediate: true,
      handler(value) {
        if (+value > this.minuteMax) {
          this.$nextTick(() => {
            this.minute = 0
          })
        } else if (+value < 0) {
          this.minute = this.getTime(this.minuteMax)
        }
      },
    },
  },
}
</script>

<style lang="scss">
.v-application {
  .dialogTimePicker {
    border-radius: 8px;
    padding: 50px 50px 50px 0;
    
    .timeBody {
      max-height: 200px;
    }
    
    .timeBlock {
      height: 170px;
      
      &#time-block-hours {
        width: 60px;
      }
      
      
      &#time-block-minutes {
        width: 31px;
      }
      
      span {
        height: 40px;
        cursor: pointer;
        
        &.current {
          color: var(--v-timePickerColorActive-base);
        }
        
        &.range {
          color: var(--v-timePickerColor-base);
        }
      }
    }
    
    .number-block {
      width: 17px;
    }
  }
  
  &.theme--dark {
    .dialogTimePicker {
      box-shadow: 0 2px 8px rgba(0, 0, 0, 0.28);
    }
  }
  
  &.theme--light {
    .dialogTimePicker {
      box-shadow: 0 2px 8px rgba(0, 0, 0, 0.08);
    }
  }
}
</style>

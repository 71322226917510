<template>
  <v-dialog
    v-model="show"
    v-bind="$attrs"
    max-width="850px"
    persistent
    overlay-color="overlayColor"
  >
    <v-card class="cardBg py-18 px-20 pt-sm-23 pr-sm-35 pb-sm-25 pl-sm-0">
      <v-row no-gutters>
        <v-col cols="12" sm="6" class="pb-8 pr-11">
          <div class="font-large-title text-uppercase dialogTitle--text text-sm-right">
            Create Buyer
          </div>
        </v-col>
      </v-row>
      
      <v-form class="pb-20" ref="form" v-model="validForm">
        <v-row no-gutters>
          <v-col
            class="d-flex flex-column justify-center pr-sm-11 pb-4 pb-sm-10"
            cols="12" sm="6"
          >
            <span class="font-normal nameField--text text-sm-right">Buyer name</span>
            <portal-target
              class="field-message font-normal error--text text-sm-right"
              name="buyerDialogName"
            ></portal-target>
          </v-col>
          
          <v-col class="pb-8 pb-sm-10" cols="12" sm="6">
            <FormField
              class="field-container"
              v-model="formData.name"
              :rules="rules"
            >
              <template #message="{ key, message }">
                <portal to="buyerDialogName" v-if="!!message">
                  {{ message }}
                </portal>
              </template>
            </FormField>
          </v-col>
          
          <v-col
            class="d-flex flex-column justify-center pr-sm-11 pb-4 pb-sm-10"
            cols="12" sm="6"
          >
            <span class="font-normal nameField--text text-sm-right">Timezone</span>
            <portal-target
              class="field-message font-normal error--text text-sm-right"
              name="buyerDialogTimezone"
            ></portal-target>
          </v-col>
          
          <v-col class="pb-8 pb-sm-10" cols="12" sm="6">
            <AutocompleteField
              class="field-container"
              v-model="formData['timezone_id']"
              :items="timezones"
              item-text="display"
              item-value="id"
              :rules="rules"
              checkBg
            >
              <template #message="{ key, message }">
                <portal to="buyerDialogTimezone" v-if="!!message">
                  {{ message }}
                </portal>
              </template>
            </AutocompleteField>
          </v-col>
          
          <v-col class="text-sm-right pr-sm-11 pb-4 pb-sm-10" cols="12" sm="6">
            <span class="font-normal nameField--text">Allow buyer to pause destinations</span>
          </v-col>
          <v-col class="pt-sm-1 pb-8 pb-sm-0" cols="12" sm="6">
            <SwitchLg
              v-model="formData['pause_targets']"
              bgColor="switcherViolet"
            />
          </v-col>
          
          <v-col class="text-sm-right pr-sm-11 pb-4 pb-sm-10" cols="12" sm="6">
            <span class="font-normal nameField--text">Allow buyer to Set concurrency</span>
          </v-col>
          <v-col class="pt-sm-1 pb-8 pb-sm-0" cols="12" sm="6">
            <SwitchLg
              v-model="formData['set_concurrency']"
              bgColor="switcherViolet"
            />
          </v-col>
          
          <v-col class="text-sm-right pr-sm-11 pb-4 pb-sm-10" cols="12" sm="6">
            <span class="font-normal nameField--text">Allow buyer to dispute call conversions</span>
          </v-col>
          <v-col class="pt-sm-1" cols="12" sm="6">
            <SwitchLg
              v-model="formData['dispute_conversion']"
              bgColor="switcherViolet"
            />
          </v-col>
          
          <v-col class="text-sm-right pr-sm-11 pb-4 pb-sm-10" cols="12" sm="6">
            <span class="font-normal nameField--text">Allow buyer to set capacity</span>
          </v-col>
          <v-col class="pt-sm-1" cols="12" sm="6">
            <SwitchLg
              v-model="formData['set_capacity']"
              bgColor="switcherViolet"
            />
          </v-col>

          <v-col class="text-sm-right pr-sm-11 pb-4 pb-sm-10" cols="12" sm="6">
            <span class="font-normal nameField--text">Allow buyer to hangup calls</span>
          </v-col>
          <v-col class="pt-sm-1" cols="12" sm="6">
            <SwitchLg
              v-model="formData['hangup_calls']"
              bgColor="switcherViolet"
            />
          </v-col>

          <v-col class="text-sm-right pr-sm-11 pb-4 pb-sm-10" cols="12" sm="6">
            <span class="font-normal nameField--text">Allow buyer to block numbers</span>
          </v-col>
          <v-col class="pt-sm-1" cols="12" sm="6">
            <SwitchLg
              v-model="formData['block_numbers']"
              bgColor="switcherViolet"
            />
          </v-col>

          <v-col class="text-sm-right pr-sm-11 pb-4 pb-sm-10" cols="12" sm="6">
            <span class="font-normal nameField--text">Allow buyer to download reports</span>
          </v-col>
          <v-col class="pt-sm-1" cols="12" sm="6">
            <SwitchLg
              v-model="formData['download_reports']"
              bgColor="switcherViolet"
            />
          </v-col>
          
          <v-col class="text-sm-right pr-sm-11 pb-4 pb-sm-8" cols="12" sm="6">
            <span class="font-large sectionTitle--text text-sm-right">CAP SETTINGS</span>
          </v-col>
          <v-col cols="12" sm="6"></v-col>
          
          <v-col class="text-sm-right pr-sm-11 pb-4 pb-sm-10" cols="12" sm="6">
            <span class="font-normal nameField--text">Global Revenue Cap, $</span>
          </v-col>
          
          <v-col class="pt-sm-1" cols="12" sm="6">
            <SwitchAndNumberInput
              v-model="revenueCapacity['global_revenue_cap']"
              :min="0"
              :step="0.01"
            />
          </v-col>
          
          <v-col class="text-sm-right pr-sm-11 pb-4 pb-sm-10" cols="12" sm="6">
            <span class="font-normal nameField--text">Monthly Revenue Cap, $</span>
          </v-col>
          
          <v-col class="pt-sm-1" cols="12" sm="6">
            <SwitchAndNumberInput
              v-model="revenueCapacity['monthly_revenue_cap']"
              :min="0"
              :step="0.01"
            />
          </v-col>
          
          <v-col class="text-sm-right pr-sm-11 pb-4 pb-sm-10" cols="12" sm="6">
            <span class="font-normal nameField--text">Daily Revenue Cap, $</span>
          </v-col>
          
          <v-col class="pt-sm-1" cols="12" sm="6">
            <SwitchAndNumberInput
              v-model="revenueCapacity['daily_revenue_cap']"
              :min="0"
              :step="0.01"
            />
          </v-col>
          
          <v-col class="text-sm-right pr-sm-11 pb-4 pb-sm-0" cols="12" sm="6">
            <span class="font-normal nameField--text">Hourly Revenue Cap, $</span>
          </v-col>
          
          <v-col class="pt-sm-1" cols="12" sm="6">
            <SwitchAndNumberInput
              v-model="revenueCapacity['hourly_revenue_cap']"
              :min="0"
              :step="0.01"
            />
          </v-col>
        </v-row>
      </v-form>
      <div class="d-flex justify-sm-end">
        <CancelButton @click="closeDialog">Cancel</CancelButton>
        
        <ActionButton class="ml-5 ml-sm-11" :loading="loading" @click="submitForm">Create</ActionButton>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import FormField from '@/components/inputs/FormField'
import AutocompleteField from '@/components/select/AutocompleteField'
import SwitchLg from '@/components/controls/SwitchLg'
import SwitchAndNumberInput from '@/components/controls/SwitchAndNumberInput'
import CancelButton from '@/components/buttons/CancelButton'
import ActionButton from '@/components/buttons/ActionButton'

import { mapState } from 'vuex'

import { createBuyer } from '@/api/buyer-routes'
  
export default {
  name: 'DialogBuyer',
  inheritAttrs: false,
  components: { FormField, AutocompleteField, SwitchLg, SwitchAndNumberInput, CancelButton, ActionButton },
  props: {
    value: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    loading: false,
    validForm: true,
    formData: {
      name: null,
      'timezone_id': null,
      'pause_targets': false,
      'set_concurrency': false,
      'dispute_conversion': false,
      'set_capacity': false,
      'hangup_calls': false,
      'block_numbers': false,
      'download_reports': false,
    },
    revenueCapacity: {
      'global_revenue_cap': null,
      'monthly_revenue_cap': null,
      'daily_revenue_cap': null,
      'hourly_revenue_cap': null,
    },
    rules: [],
  }),
  computed: {
    show: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      }
    },
    ...mapState({
      timezones: state => state.timezones,
    }),
  },
  methods: {
    async submitForm() {
      await this.formValidation()

      if (this.$refs.form.validate()) {
        Object.keys(this.revenueCapacity).forEach(name => {
          this.revenueCapacity[name] = !!Number(this.revenueCapacity[name]) ? this.revenueCapacity[name] : null
        })

        let formData = {
          ...this.formData,
          ...this.revenueCapacity
        }

        this.goToCreateBuyer(formData)
      }
    },
    async goToCreateBuyer(formData) {
      this.loading = true
      
      const { success, payload, message } = await createBuyer(formData)
      
      if (success) {
        this.$emit('createSuccess', payload)
        this.$notify({ type: 'success-bg', text: message })
        this.closeDialog()
      } else {
        !!message && this.$notify({ type: 'error-bg', duration: 15000, text: message })
      }
      
      this.loading = false
    },
    formValidation() {
      this.rules = [
        v => !!v ||  'This field is required'
      ]
    },
    closeDialog() {
      this.show = false
    }
  },
  watch: {
    show(value) {
      if (!value) {
        this.rules = []
        this.formData = {
          name: null,
          'timezone_id': null,
          'pause_targets': false,
          'set_concurrency': false,
          'dispute_conversion': false,
          'set_capacity': false,
          'hangup_calls': false,
          'block_numbers': false,
          'download_reports': false,
        }
        this.revenueCapacity = {
          'global_revenue_cap': null,
          'monthly_revenue_cap': null,
          'daily_revenue_cap': null,
          'hourly_revenue_cap': null,
        }
        this.loading = false
      }
    },
  },
}
</script>

<style lang="scss">
.switcher > .switcherViolet {
  height: 20px;
}
</style>

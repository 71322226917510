<template>
  <div>
    <v-card
      class="transparent hourlyTimePicker font-normal rounded-8"
      :class="{ 'active-picker': active, 'error-range': !valid}"
      width="100"
      height="180"
      outlined
    >
      <div class="d-flex flex-column">
        <div class="switch-content" v-if="simple">
          <div class="cardTimePickerDay--text text-center pb-2">Monday</div>
          
          <div class="cardTimePickerDay--text text-center">Sunday</div>
        </div>
  
        <div class="d-flex switch-content" v-else>
          <SwitchLg
            :value="active"
            @input="changeActive"
            bgColor="switcherViolet"
          />
          <div
            class="advanced pl-6"
            :class="active ? 'cardTimePickerDay--text' : 'cardTimePickerDayOff--text'"
          >{{ day }}</div>
        </div>
      
        <div class="open-time">
          <div
            class="text-center"
            :class="active ? 'cardTimePickerCloseOpen--text' : 'cardTimePickerTimeOff--text'"
          >
            OPEN
          </div>
          
          <div class="text-center">
            <v-btn
              class="pa-0"
              :ripple="false"
              height="17"
              text
              :disabled="!active"
              @click="openTimePicker(timeRange.start, 'start')"
            >
              <span :class="active ? 'cardTimePickerTime--text' : 'cardTimePickerTimeOff--text'">
                {{ formatTime(timeRange.start) }}
              </span>
            </v-btn>
          </div>
        </div>
      
        <div class="close-time">
          <div
            class="text-center"
            :class="active ? 'cardTimePickerCloseOpen--text' : 'cardTimePickerTimeOff--text'"
          >
            CLOSE
          </div>
          
          <div class="text-center">
            <v-btn
              class="pa-0"
              :ripple="false"
              height="17"
              text
              :disabled="!active"
              @click="openTimePicker(timeRange.end, 'end')"
            >
            <span
              class="text-none"
              :class="active ? 'cardTimePickerTime--text' : 'cardTimePickerTimeOff--text'"
            >{{ formatTime(timeRange.end) }}</span>
            </v-btn>
          </div>
        </div>
      </div>
      
      <CustomTimePicker
        v-model="timePicker"
        :timeOption="timeOption"
        :pickerHour="pickerHour"
        :pickerMinute="pickerMinute"
        @updateTime="updateTime"
      />
    </v-card>
  </div>
</template>

<script>
import SwitchLg from '@/components/controls/SwitchLg'
import CustomTimePicker from '@/components/pickers/CustomTimePicker'

import { format } from 'date-fns'
  
export default {
  name: 'HourlyTimePicker',
  inheritAttrs: false,
  components: { SwitchLg, CustomTimePicker },
  props: {
    simple: {
      type: Boolean,
      default: true
    },
    day: {
      type: String,
      default: ''
    },
    active: {
      type: Boolean,
      default: true
    },
    timeOption: {
      type: Boolean,
      default: false
    },
    valid: {
      type: Boolean,
      required: true
    },
    timeRange: {
      type: Object,
      default: () => ({
        start: [9, 0],
        end: [20, 0],
      })
    },
  },
  data: () => ({
    timePicker: false,
    pickerHour: 0,
    pickerMinute: 0,
    pickerOption: null,
  }),
  methods: {
    changeActive() {
      this.$emit('update:active', !this.active)
    },
    formatTime(time) {
      if (this.timeOption) {
        return format(new Date(2020, 1, 1, time[0], time[1] ), 'HH : mm')
      } else {
        return format(new Date(2020, 1, 1, time[0], time[1] ), 'hh : mm a').toLowerCase()
      }
    },
    openTimePicker(time, option) {
      this.pickerOption = option
      this.pickerHour = +time[0]
      this.pickerMinute = +time[1]
      this.timePicker = true
    },
    updateTime(range) {
      const updateRange = Object.assign({}, this.timeRange, { [this.pickerOption]: range })
      if (
        updateRange.start[0] > updateRange.end[0] ||
        updateRange.start[0] === updateRange.end[0] &&
        updateRange.start[1] >= updateRange.end[1]
      ) {
        this.$emit('update:valid', false)
      } else {
        this.$emit('update:valid', true)
      }
      this.$emit('update:timeRange', updateRange)
      this.$emit('updateRange', true)
    },
  },
  watch: {
    active: {
      handler(value) {
        this.$emit('update:active', value)
        this.$emit('updateRange', true)
      },
    },
  },
}
</script>

<style lang="scss">
.v-card.hourlyTimePicker {
  margin-top: 10px;
  margin-right: 10px;
  border-color: var(--v-cardTimePickerBorderOff-base)!important;
  
  &.active-picker:not(.error-range) {
    border-color: var(--v-cardTimePickerBorder-base)!important;
  }
  
  &.error-range {
    border-color: var(--v-pink-base)!important;
  }
  
  & .switch-content {
    height: 53px;
    margin: 23px 20px 0 20px;
    
    & .switcherViolet {
      width: 20px;
      position: relative;
      top: 20px;
      transform: rotate(-90deg);
    }
    
    & .simple {
      & .v-divider {
        margin-left: 10px!important;
      }
    }
    
    & .advanced {
      padding-top: 10px;
    }
  }
  
  & .v-btn--text {
    min-width: 30px!important;
    &::before {
      opacity: 0;
    }
  }
  
  & .v-input .v-input__control > .v-input__slot {
    padding: 0 9px!important;
  }
  
  & .close-time {
    padding-top: 13px;
  }
}
</style>

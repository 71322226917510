<template>
  <v-row no-gutters>
    <v-col
      class="text-sm-end font-normal nameField--text pr-sm-11 pb-4 pb-sm-0"
      cols="12" sm="4" md="3"
    >Global Cap</v-col>
  
    <v-col class="pb-10" cols="12" sm="8" md="9">
      <SwitchAndNumberInput v-model="capacity['global_cap']" :min="0" />
    </v-col>
  
    <v-col
      class="text-sm-end font-normal nameField--text pr-sm-11 pb-4 pb-sm-0"
      cols="12" sm="4" md="3"
    >Monthly Cap</v-col>
  
    <v-col class="pb-10 d-flex" cols="12" sm="8" md="9">
      <SwitchAndNumberInput v-model="capacity['monthly_cap']" :min="0" />
    </v-col>
  
    <v-col
      class="text-sm-end font-normal nameField--text pr-sm-11 pb-4 pb-sm-0"
      cols="12" sm="4" md="3"
    >Daily Cap</v-col>
  
    <v-col class="pb-10 d-flex" cols="12" sm="8" md="9">
      <SwitchAndNumberInput v-model="capacity['daily_cap']" :min="0" />
    </v-col>
  
    <v-col
      class="text-sm-end font-normal nameField--text pr-sm-11 pb-4 pb-sm-0"
      cols="12" sm="4" md="3"
    >Hourly Cap</v-col>
  
    <v-col class="pb-10 d-flex" cols="12" sm="8" md="9">
      <SwitchAndNumberInput v-model="capacity['hourly_cap']" :min="0" />
    </v-col>
  </v-row>
</template>

<script>
import SwitchAndNumberInput from '@/components/controls/SwitchAndNumberInput'

export default {
  name: 'CapSettings',
  inheritAttrs: false,
  components: { SwitchAndNumberInput },
  props: {
    capSettings: {
      type: Object,
      required: true
    }
  },
  computed: {
    capacity: {
      get() {
        return this.capSettings
      },
      set(value) {
        this.$emit('capSettings:update', value)
      }
    }
  }
}
</script>

<template>
  <div class="input-number-time-picker-container">
    <v-text-field
      class="number-input-field"
      v-model="fieldValue"
      v-bind="$attrs"
      v-on="$listeners"
      flat
      solo
      background-color="transparent"
      @keypress="onlyNumbers"
      @change="checkValue"
      type="number"
      :min="min"
      :max="max"
      hide-details
    />
  </div>
</template>

<script>
export default {
  name: 'InputNumberTimePicker',
  inheritAttrs: false,
  props: {
    value: {
      type: Number | String,
      default: 1
    },
    min: {
      type: Number | String,
      default: 0
    },
    max: {
      type: Number | String,
      default: 23
    },
  },
  computed: {
    fieldValue: {
      get() {
        return this.formatValue(this.value)
      },
      set(value) {
        if (+value < this.min || +value > this.max) {
          this.$emit('input', +value)
        } else {
          this.$emit('input', value)
        }
      },
    },
  },
  methods: {
    formatValue(value) {
      return `${value}`.length === 1 ? `0${value}` : +value
    },
    onlyNumbers(event) {
      const regex = new RegExp('^[0-9]+$')
      const key = String.fromCharCode(!event.charCode ? event.which : event.charCode)
      if (!regex.test(key)) {
        event.preventDefault()
        return false
      }
    },
    checkValue(val) {
      if (val < this.min) {
        this.fieldValue = this.min
      } else if (val > this.max) {
        this.fieldValue = this.max
      }
    },
  },
}
</script>

<style lang="scss">
.v-application {
  .input-number-time-picker-container {
    width: 35px;
    height: 31px;
    
    .v-input.v-text-field {
      & > .v-input__control {
        & > .v-input__slot {
          padding: 0!important;
          min-height: 31px!important;
          
          & > .v-text-field__slot {
            height: 31px!important;
            & > input {
              padding: 7px 0;
              text-align: center;
              font-weight: 500!important;
              font-size: 14px!important;
              line-height: 17px!important;
              letter-spacing: 0.008em!important;
            }
          }
        }
      }
    }
  }
}
</style>